<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <headerNav></headerNav>
    <div class="bodyr">
      <navMenu style="z-index:10"></navMenu>
      <router-view/>
    </div>
    <footerMenu></footerMenu>
  </div>
</template>
<script>
import headerNav from "@/components/headerNav"
import navMenu from '@/components/navMenu'
import footerMenu from '@/components/footer'
export default{
    components:{
      headerNav,
      navMenu,
      footerMenu
    }
}
</script>
<style lang="less">
*{
  padding: 0;
  margin: 0;
}
body{
    background: #0C0D14;
    box-sizing: border-box;
    padding-top: 50px;
}
.bodyr{
  background: #0C0D14;
  color: #fff;
  display: flex;
}
</style>
