<template>
    <div>
        <div class="body-r">
            <div class="mobile-search" v-if="showSearch">
                <div class="search-body">
                    <div class="search-inp">
                        <img src="@/assets/slices/ic_sousuo.png" alt="">
                        <input v-model="searchText" placeholder="keywords" @keyup.enter="searchGame()"
                        @focus="getFocus"
                        type="text">
                    </div>
                    <div class="search-btn" @click="searchGame()">
                        search
                    </div>
                </div>
<!--                <div class="search-area" v-if="showSearch&&showSearchList">-->
<!--                    <p>SEARCH GAME BY KEY "{{searchText}}"</p>-->
<!--                    <div class="search-result" v-if="searchList.length>1">-->
<!--                        <div class="search-card" v-for="item in searchList" :key="item.id" @click="goDetails(item)">-->
<!--                            <img :src="item.logo" alt="">-->
<!--                            <span>{{item.title}}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="mobile-top"  v-if="showSearch">
                <div class="mobile-body">
                    <img src="@/assets/phone/500.png" alt="">
                    <p>500+ Games</p>
                </div>
                <div class="mobile-body">
                    <img src="@/assets/phone/needed.png" alt="">
                    <p>No install needed</p>
                </div>
                <div class="mobile-body">
                    <img src="@/assets/phone/play.png" alt="">
                    <p>Play withfriends</p>
                </div>
            </div>
            <adContent></adContent>
            <div class="mobile-Carousel" v-if="showSearch">
                <div class="mobile-Carousel-body">
                    <div class="Carousel-card"
                    v-for="item in topListF"
                    :key="item.id"
                    @click="goDetails(item)">
                        <img :src="item.logo" alt="">
                        <p>{{item.title}}</p>
                    </div>
                </div>
            </div>
            <div class="mobile-second" v-if="showSearch">
                <div class="second-card"
                v-for="item in sixList"
                :key="item.id"
                @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p>{{item.title}}</p>
                </div>
            </div>
            <div class="mobile-Carousel" v-if="showSearch">
                <div class="mobile-Carousel-body">
                    <div class="Carousel-card"
                    v-for="item in topListS"
                    :key="item.id"
                    @click="goDetails(item)">
                        <img :src="item.logo" alt="">
                        <p>{{item.title}}</p>
                    </div>
                </div>
            </div>
            <div class="body-r-top" v-if="!showSearch">
                <div class="top-animation">
                    <div class="top-body" v-for="(item,i) in topList" :key="i">
                        <div class="top-body-l">
                            <img :src="item.list[0].logo" alt="" @click="goDetails(item.list[0])">
                        </div>
                        <div class="top-body-r">
                            <img :src="item.list[1].logo" alt="" @click="goDetails(item.list[1])">
                            <img :src="item.list[2].logo" alt="" @click="goDetails(item.list[2])">
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-r-class" v-for="item in gameList" :key="item.title">
                <p class="class-name">
                    <span>{{item.title}}</span>
                    <span v-if="showSearch">
                        >>
                    </span>
                </p>
                <div class="mobile-Carousel" v-if="showSearch">
                    <div class="game-body">
                        <div
                        class="game-card"
                        v-for="items in item.games"
                        :key="items.id"
                        @click="goDetails(items)">
                            <img :src="items.logo" alt="">
                            <p>{{items.title}}</p>
                        </div>
                    </div>
                </div>
                <div v-else
                class="game-card"
                v-for="items in item.games"
                :key="items.id"
                @click="goDetails(items)">
                    <div >
                        <img :src="items.logo" alt="">
                    </div>
                    <span>{{items.title}}</span>
                </div>
            </div>
            <div class="game-blurb noselect">
                {{ blurbUrl }} is a global leader in online games, offering a diverse range of free-to-play titles across various genres including action, sports, strategy, and puzzle. With a rich history spanning over two decades, Miniclip has established itself as a trusted and popular destination for gamers worldwide. The platform boasts a user-friendly interface and seamless gameplay, ensuring that players can enjoy their favorite games without any hassle. Miniclip's commitment to quality and innovation has led to the development of numerous hit games, such as 8 Ball Pool, Agar.io, and Soccer Stars. The site also features a vibrant community where players can connect, compete, and share their experiences.
            </div>
        </div>
    </div>
</template>

<script>
// import gemaData from '@/utils/gemedata/data'
import eventBus from '@/utils/eventBus.js'
import { getRandomNumbers, isMobile } from "@/utils/api"
import { getGameList, getGameByKey, getGame } from '@/api/games'
import iconList from '@/utils/navMenu'
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                gameList:[],
                gameListC:[],
                topListF:[],
                topListS:[],
                topList:[],
                allList:[],
                searchList:[],
                sixList:[],
                showSearch: false,
                searchText:null,
                showSearchList: false,
                showList:true,
                lang:0,
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            
            let key = this.$route.params.key;
            let searchText = this.$route.params.searchText;
            if(key){
                this.getClass(key);
            }else if(searchText){
                this.searchGame(searchText);
            }else{
                this.getList();
            }
            eventBus.$emit('showlist',true)

            eventBus.$on('gameList',key=>{                
                this.getClass(key);
            });
            eventBus.$on('searchKey',key=>{
                this.searchGame(key);
            })
            eventBus.$on('showlist',item=>{                
                this.showList = item;
            })
            
            if(isMobile()){
                this.showSearch = true;
                eventBus.$emit('showlist',false);
            }
            
        },
        methods: {
            getFocus(){
                this.showSearchList = true;
            },
            getList(){
                getGameList().then(async res=>{
                    Object.keys(res).forEach(key=>{
                        res[key].forEach(item=>{
                            this.allList.push(item)
                        })
                        this.gameList.push({
                            title: key,
                            games: res[key]
                        });
                    })
                    
                    this.gameListC = JSON.parse(JSON.stringify(this.gameList));

                    let topListF = await getGame({count:10})
                    this.topListF = topListF;

                    let topListS = await getGame({count:10})
                    this.topListS = topListS;

                    let sixList = await getGame({count:6})
                    this.sixList = sixList;

                    for (let i = 0; i < 6; i++) {
                        let res = await getGame({count:3})
                        this.topList.push({
                            list:res
                        })
                    }
                });        
            },
            getClass(key){                        
                this.selectName = key.name;
                
                if(key.name==='All'){
                    this.gameList = this.gameListC;
                }else{
                    // this.gameListC.forEach(item=>{
                    //     if(item.title==key.name){
                    //         this.gameList = [
                    //             {
                    //                 title: key.name,
                    //                 games: item.games
                    //             }
                    //         ]
                    //     }
                    // })
                    getGameByKey({category:key.name}).then(res=>{
                        this.gameList = [
                            {
                                title: key.name,
                                games: res
                            }
                        ]
                        this.gameList.forEach(item=>{
                            iconList.forEach(keys=>{
                                if(item.title == keys.name){
                                    item.url = keys.wurl
                                }
                            })
                        })                                     
                    })
                }
            },
            goDetails(item){
                let id = item.id
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            },
            getRandomGames(str,end,len){
                let gamelist = getRandomNumbers(str,end,len);

                gamelist.forEach((item,i)=>{
                    gamelist.splice(i,1,this.allList[item])
                })

                return gamelist;
            },
            searchGame(){
                this.$router.push({
                    path:'/selectGame',
                    query: {
                        id:'search',
                        key:this.searchText
                    }
                })
            },
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .body-r{
        margin-top: 70px;
        .mobile-search{
            // padding: 9px 5px 11px;
            margin: 9px 10px 11px;
            box-sizing: border-box;
            .search-body{
                display: flex;
                justify-content: space-between;
                .search-inp{
                    width: 76%;
                    height: 40px;
                    background: #373952;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    input{
                        height: 40px;
                        background: #373952;
                        border-radius: 10px;
                        display: block;
                        font-size: 16px;
                        border: none;
                        outline: none;
                        background-color: #373952;
                        color: #888;
                    }
                }
                .search-btn{
                    text-align: center !important;
                    line-height: 40px !important;
                    width: 83px;
                    height: 40px;
                    background: #6842FF;
                    border-radius: 10px;
                    border: none;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 18px;
                    color: #FFF0F0;
                    line-height: 25px;
                    text-align: left;
                    font-style: normal;
                }
                // .search-text{
                //     width: calc(100% - 20px);
                //     background-color: #333333;
                //     top: 100px;
                //     position: absolute;
                //     >div{
                //         margin-left: 5px;
                //         height: 30px;
                //         line-height: 30px;
                //         border-bottom: 1px solid #FFF;
                //     }
                // }
            }
            .search-area{
                margin: 18px 0;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 25px;
                text-align: left;
                font-style: normal;
                .search-result{
                    display: flex;
                    flex-wrap: wrap;
                    // justify-content: space-between;
                    .search-card{
                        margin-left: 8px;
                        width: 115px;
                        img{
                            border-radius: 10px;
                            width: 100%;
                            height: 115px;
                        }
                        span{
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 20px;
                            text-align: left;
                            font-style: normal;
                        }
                    }
                }
            }
        }
        .mobile-top{
            display: flex;
            margin: 22px 14px;
            .mobile-body{
                display: flex;
                align-items: center;
                img{
                    width: 27px;
                    height: 27px;
                }
                p{
                    margin-left: 6px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .mobile-Carousel{
            width: calc(100vw - 30px);
            margin: 0 auto;
            overflow: hidden;
            .mobile-Carousel-body{
                overflow: auto; /* 水平和垂直滚动条 */
                white-space: nowrap; /* 文字不换行 */
                word-break: break-all; /* 允许在单词内换行 */
                width: 100%; /* 容器宽度 */
                .Carousel-card{
                    width: 216px;
                    height: 216px;
                    display: inline-block; /* 内容作为行内块显示 */
                    margin-right: 10px;
                    // border: 0.5px solid #fff;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 18px;
                    }
                    p{
                        width: 100%;
                        height: 36px;
                        background: rgba(0, 0, 0, 0.5);
                        border-radius: 0px 0px 18px 18px;
                        padding-left: 19px;
                        box-sizing: border-box;
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }
        .mobile-second{
            margin: 22px 14px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .second-card{
                width: 160px;
                width: 160px;
                background: #44476E;
                border-radius: 16px;
                margin-bottom: 16px;
                img{
                    border-radius: 16px;
                    width: 160px;
                    height: 160px;
                }
                p{
                    height: 42px;
                    padding-left: 7px;
                    line-height: 42px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .class-after{
            &::after{
                content: '';
                flex: auto;
            }
        }
        .body-r-class{
            width: calc(100vw - 36px);
            overflow: hidden;
            margin: 22px 0 0 18px;
            .class-name{
                // width: 100%;
                // margin: 0 18px 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .game-body{
                overflow: auto; /* 水平和垂直滚动条 */
                white-space: nowrap; /* 文字不换行 */
                word-break: break-all; /* 允许在单词内换行 */
                // width: 100%;
                .game-card{
                    width: 216px;
                    display: inline-block;
                    width: 102px;
                    margin-left: 8px;
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 20px;
                        text-align: left;
                        font-style: normal;
                    }
                    img{
                        height: 102px;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .body-r{
        margin-left: 160px;
        padding:30px 0 0 19px;
        box-sizing: border-box;
        margin-top: 60px;
        .body-r-top{
            // width: calc(100vw - 150px);
            width: calc(100vw - 205px);
        }
        .game-card{
            width: 226px;
            margin-right: 19px;
            span{
                font-size: 16px;
            }
            img{
                height: 226px;
            }
        }
    }
}
.body-r{
    @keyframes marquee {
        from {
            // translate: 0%;
            transform: translateX(0);
        }
        to {
            // translate: -1000px;
            transform: translateX(-100%);

        }
    }
    .body-r-top{
        height: 364px;
        overflow: hidden;
        .top-animation{
            z-index: -500;
            display: flex;
            animation: marquee 20s linear infinite;
            &:hover{
                animation-play-state: paused;
            }
            .top-body{
                display: flex;
                margin-right: 35px;
                .top-body-l{
                    width: 360px;
                    height: 360px;
                    margin-right: 23px;
                    img{
                        border-radius: 20px;
                        width: 100%;
                        height: 100%;
                        &:hover{
                            border: 2px solid #6842FF;
                        }
                    }
                }
                .top-body-r{
                    width: 171px;
                    height: 360px;
                    img{
                        width: 171px;
                        height: 171px;
                        border-radius: 20px;
                        margin-bottom: 18px;
                        &:hover{
                            border: 2px solid #6842FF;
                        }
                    }
                }
            }
        }
    }
    .body-r-class{
        // width: 100%;
        display: flex;
        flex-wrap: wrap;
        .class-name{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 19px;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 33px;
                text-align: left;
                font-style: normal;
            }
            img{
                width: 31px;
                height: 31px;
            }
        }
        .game-card{
            margin-bottom: 15px;
            cursor: pointer;
            span{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
            img{
                border-radius: 7px;
                width: 100%;
                &:hover{
                    transform: scale(1.1);
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>